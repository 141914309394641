
<template>
  <div>
    <CCard>
      <CCardBody>
        <CContainer id='card-view' v-if="cardView">
          <CRow>
            <CCol lg="3" sm="4" v-for="cat in geofenceCategories" :key="cat.getId()">
              <GeofenceCategoryCard :category="cat" @setGeoCatToActive="setActiveGeoCat">
              </GeofenceCategoryCard>
            </CCol>
          </CRow>
        </CContainer>
        <CContainer id='list-view' v-else>
          <CDataTable
            :items="geofenceCategories"
            :fields="fields"
            :items-per-page="10"
            hover
            pagination
            cleaner
            clickable-rows
            @row-clicked="setHoveredRow"
            :table-filter="{
              external: false,
              lazy: false,
              label: 'Search',
              placeholder: 'Search for a Tag',
            }"
            sorter
          >
          <template #Name="{ item }">
            <td>
              <b> {{ item.getName() }} </b>
            </td>
          </template>
          <template #Description="{ item }">
            <td>
              <span> {{ item.getDescription() }} </span>
            </td>
          </template>
          <template #Select="{ item }">
            <td>
              <CButton
                color="info"
                @click="setActiveGeoCat(item.uid)"
                variant="ghost"
              > 
              Modify
              </CButton>
            </td>
          </template>
          </CDataTable>
        </CContainer>
      </CCardBody>
      <CCardFooter>
        <div class="d-flex justify-content-between">
          <span></span>
          <CButton @click="addCategory" color="success"> add </CButton>
        </div>
      </CCardFooter>
    <CModal
      :show="true"
      v-if="activeGeoCatUId"
      centered
      fade
      color="secondary"
      border-color="secondary"
      size="xl"
    >
      <template #header-wrapper>
        <CCardHeader :color="$theme.backColor">
          <div class="default-card-header">
            <CIcon name="cil-bell"> </CIcon>
            {{title}}
          </div>
        </CCardHeader>
      </template>
      <template #body-wrapper>
        <GeofenceCategoryForm
          :uid="activeGeoCatUId"
          :editType="editType"
          @closeForm="closeModal"
        />
      </template>
      <template #footer>
        <div hidden="true"> </div>
      </template>
    </CModal>
    </CCard>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import GeofenceCategoryCard from '@/components/geofence/GeofenceCategoryCard'
import GeofenceCategoryForm from '@/components/forms/GeofenceCategoryForm'
import { geoCatFormType } from '@/lib/geofencing_lib';
import { generateRandomId } from '@/lib/geofencing_lib';


export default {
  name: "GeofenceCategoriesView",
  components: {
    GeofenceCategoryCard,
    GeofenceCategoryForm,
  },
  data() {
    return {
      activeGeoCatUId: null,
      editType: null,
      fields:[
        "Name",
        "Description",
        "Select"
      ],
      cardView: false,
      CTableData: [],
      hoverUId: null,
    };
  },
  async created () {
    try {
      await this.$app.loadGeofenceCategories();
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    ...mapGetters({
      geofenceCategories: 'geofenceCategories'
    }),

    title () {
      if (this.editType == geoCatFormType.MODIFY) {
        return "Edit Geofence Category";
      } else if (this.editType == geoCatFormType.ADD) {
        return "Add Geofence Category";
      } else {
        console.error("unsupported edit type", this.editType);
        return "";
      }
    },

    toggleViewBtnText () {
      return this.cardView? "Table View" : "Card View";
    }
  },
  methods: {
    setHoveredRow (row) {
      this.hoverUId = row.uid;
    },

    setActiveGeoCat(uid) {
      this.activeGeoCatUId = uid;
      this.editType = geoCatFormType.MODIFY;
    },

    addCategory () {
      this.activeGeoCatUId = generateRandomId();
      this.editType = geoCatFormType.ADD;
    },

    closeModal() {
      console.log("closeModal");
      this.activeGeoCatUId = null;
      this.editType = null;
    },

    toggleView () {
      this.cardView = !this.cardView;
    }
  },
}
</script>

<style scoped>
</style>
