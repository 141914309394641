var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCardBody",
    [
      _c("CForm", [
        _c(
          "div",
          [
            _c("CInput", {
              attrs: {
                label: "name",
                type: "text",
                placeholder: "Type in a name",
                isValid: !_vm.$v.inputName.$invalid,
                invalidFeedback: "invalid name"
              },
              model: {
                value: _vm.inputName,
                callback: function($$v) {
                  _vm.inputName = $$v
                },
                expression: "inputName"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("CTextarea", {
              attrs: {
                label: "description",
                type: "text",
                placeholder: "Type in a short description",
                isValid: !_vm.$v.inputDescription.$invalid,
                invalidFeedback: "invalid description"
              },
              model: {
                value: _vm.inputDescription,
                callback: function($$v) {
                  _vm.inputDescription = $$v
                },
                expression: "inputDescription"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "CButton",
              {
                attrs: { disabled: !_vm.validated, color: "success" },
                on: { click: _vm.submit }
              },
              [_vm._v(" Confirm ")]
            ),
            _c("CButton", { on: { click: _vm.cancel } }, [_vm._v(" Cancel ")])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }