var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        { staticStyle: { height: "200px" } },
        [
          _c("CCardHeader"),
          _c(
            "CCardBody",
            [
              _c(
                "CRow",
                [
                  _c("CCol", { attrs: { col: "3", id: "image" } }, [
                    _vm._v(" image ")
                  ]),
                  _c(
                    "CCol",
                    { attrs: { col: "9", id: "name-and-description" } },
                    [
                      _c("CRow", { attrs: { id: "name" } }, [
                        _vm._v(" " + _vm._s(_vm.name) + " ")
                      ]),
                      _c("CRow", { attrs: { id: "description" } }, [
                        _vm._v(" description ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CRow",
                { attrs: { id: "buttons" } },
                [
                  _c(
                    "CCol",
                    { attrs: { col: "6" } },
                    [
                      _c("CButton", { on: { click: _vm.openModify } }, [
                        _vm._v(" modify ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { col: "6" } },
                    [_c("CButton", [_vm._v(" button 2 ")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }