<template>
  <CCardBody>
      <CForm>
        <div>
          <CInput
            label="name"
            type="text"
            placeholder="Type in a name"
            v-model="inputName"
            :isValid="!$v.inputName.$invalid"
            invalidFeedback="invalid name"
          />
        </div>

        <div>
          <CTextarea
            label="description"
            type="text"
            placeholder="Type in a short description"
            v-model="inputDescription"
            :isValid="!$v.inputDescription.$invalid"
            invalidFeedback="invalid description"
          />
        </div>
        <div>
          <CButton @click="submit" :disabled="!validated" color="success">
            Confirm
          </CButton>
          <CButton @click="cancel">
            Cancel
          </CButton>
        </div>
      </CForm>
  </CCardBody>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import {
  GeofenceCategory,
} from "@/lib/geofencing_lib";

import { geoCatFormType } from "@/lib/geofencing_lib"

export default {
  name: "GeofenceCategoryForm",
  props: {
    uid: {
      type: String
    },
    editType: {
      type: String,
    }
  },
  data() {
    return {
      inputDescription: null,
      inputName: null, 
    };
  },
  validations: {
    inputDescription: {
      maxLengthValue: maxLength(200),      
    },
    inputName: {
      required,
      maxLengthValue: maxLength(25)
    },
  },
  mixins: [validationMixin],
  methods: {
    submit() {
      const category = new GeofenceCategory(
        null,
        this.inputName,
        this.inputDescription,
        this.uid,
      )

      const body = category.castAsApiBody();

      if (this.editType == geoCatFormType.ADD) {
        this.$app.addGeofenceCategory(body);
      } else if (this.editType == geoCatFormType.MODIFY) {
        this.$app.modifyGeofenceCategory(category.getUId(), body);
      } else {
        console.error("unsupported edit type", this.editType);
      }
      this.$emit("closeForm");
    },

    cancel() {
      this.$emit("closeForm");
    },
  },
  computed: {
    ...mapGetters({
      geofenceCategories: "geofenceCategories",
    }),

    validated() {
      return !this.$v.inputDescription.$invalid &&
             !this.$v.inputName.$invalid;
    },
    title () {
      if (this.editType == geoCatFormType.ADD) {
        return "Add a geofence category";
      } else if (this.editType == geoCatFormType.MODIFY) {
        return "Modify a geofence category";
      } else {
        console.error("unsupported edit type", this.editType);
        return "";
      }
    },

    category () {
      let result;
      if (this.editType == geoCatFormType.MODIFY) {
        result = this.geofenceCategories.find(_cat => {
          return _cat.getUId() == this.uid;
        })
      } else if (this.editType == geoCatFormType.ADD) {
        result = null;
      } else {
        console.error('unsupported edit type', this.editType);
      }
      return result;
    },


  },
  created () {
    if (this.category) {
      this.inputName = this.category.getName();
      this.inputDescription = this.category.getDescription();
    }
  }
};
</script>
