var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c(
            "CCardBody",
            [
              _vm.cardView
                ? _c(
                    "CContainer",
                    { attrs: { id: "card-view" } },
                    [
                      _c(
                        "CRow",
                        _vm._l(_vm.geofenceCategories, function(cat) {
                          return _c(
                            "CCol",
                            { key: cat.getId(), attrs: { lg: "3", sm: "4" } },
                            [
                              _c("GeofenceCategoryCard", {
                                attrs: { category: cat },
                                on: { setGeoCatToActive: _vm.setActiveGeoCat }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "CContainer",
                    { attrs: { id: "list-view" } },
                    [
                      _c("CDataTable", {
                        attrs: {
                          items: _vm.geofenceCategories,
                          fields: _vm.fields,
                          "items-per-page": 10,
                          hover: "",
                          pagination: "",
                          cleaner: "",
                          "clickable-rows": "",
                          "table-filter": {
                            external: false,
                            lazy: false,
                            label: "Search",
                            placeholder: "Search for a Tag"
                          },
                          sorter: ""
                        },
                        on: { "row-clicked": _vm.setHoveredRow },
                        scopedSlots: _vm._u([
                          {
                            key: "Name",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("td", [
                                  _c("b", [
                                    _vm._v(" " + _vm._s(item.getName()) + " ")
                                  ])
                                ])
                              ]
                            }
                          },
                          {
                            key: "Description",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(item.getDescription()) + " "
                                    )
                                  ])
                                ])
                              ]
                            }
                          },
                          {
                            key: "Select",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "CButton",
                                      {
                                        attrs: {
                                          color: "info",
                                          variant: "ghost"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.setActiveGeoCat(item.uid)
                                          }
                                        }
                                      },
                                      [_vm._v(" Modify ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
            ],
            1
          ),
          _c("CCardFooter", [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between" },
              [
                _c("span"),
                _c(
                  "CButton",
                  {
                    attrs: { color: "success" },
                    on: { click: _vm.addCategory }
                  },
                  [_vm._v(" add ")]
                )
              ],
              1
            )
          ]),
          _vm.activeGeoCatUId
            ? _c("CModal", {
                attrs: {
                  show: true,
                  centered: "",
                  fade: "",
                  color: "secondary",
                  "border-color": "secondary",
                  size: "xl"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header-wrapper",
                      fn: function() {
                        return [
                          _c(
                            "CCardHeader",
                            { attrs: { color: _vm.$theme.backColor } },
                            [
                              _c(
                                "div",
                                { staticClass: "default-card-header" },
                                [
                                  _c("CIcon", { attrs: { name: "cil-bell" } }),
                                  _vm._v(" " + _vm._s(_vm.title) + " ")
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "body-wrapper",
                      fn: function() {
                        return [
                          _c("GeofenceCategoryForm", {
                            attrs: {
                              uid: _vm.activeGeoCatUId,
                              editType: _vm.editType
                            },
                            on: { closeForm: _vm.closeModal }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "footer",
                      fn: function() {
                        return [_c("div", { attrs: { hidden: "true" } })]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2351190912
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }