<template>
  <div>
    <CCard style="height: 200px">
      <CCardHeader>

      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol col="3" id="image">
            image
          </CCol>
          <CCol col="9" id="name-and-description">
            <CRow id='name'>
              {{name}}
            </CRow>
            <CRow id='description'>
              description
            </CRow>
          </CCol>
        </CRow>
        <CRow id='buttons'>
          <CCol col="6">
            <CButton @click="openModify">
              modify
            </CButton>
          </CCol>
          <CCol col="6">
            <CButton>
              button 2
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>



<script>
import { GeofenceCategory } from '@/lib/geofencing_lib';
export default {
  name: "GeofenceCategoryCard",
  props: {
    category: {
      type: GeofenceCategory,
      default: null,
    }
  },
  computed: {
    name() {
      return this.category? this.category.getName(): null;
    }
  },
  methods: {
    openModify() {
      this.$emit("setGeoCatToActive", this.category.getUId());
    }
  }
};
</script>

<style scoped>
</style>
